import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/AboutUs.css';
import { Link } from 'react-router-dom';
import Image2 from '../images/aboutusimage.webp';

function AboutUs() {
  const textContentRef = useRef(null);
  const imageContainerRef = useRef(null);
  const textContent2Ref = useRef(null);
  const requestQuoteRef = useRef(null);
  const textContent3Ref = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount

    const observerOptions = {
      threshold: 0.35, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, observerOptions);

    const elementsToObserve = [
      textContentRef.current,
      imageContainerRef.current,
      textContent2Ref.current,
      requestQuoteRef.current,
      textContent3Ref.current,
    ];

    elementsToObserve.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="aboutus-main-content">
      <div className="aboutus-content-container">
        <Header isScrolledInitially={true} />
        <div className="aboutus-vertical-line"></div>
        <div className="aboutus-section-1">
          <div className="aboutus-text-content" ref={textContentRef}>
            <h1>About Us</h1>
            <p>
              At Quan Freight Management, we provide reliable freight services to ensure your goods are transported safely and efficiently across Australia. Our expert team and state-of-the-art technology guarantee timely delivery and cost-effective solutions.
            </p>
          </div>
          <div className="aboutus-image-container" ref={imageContainerRef}>
            <img src={Image2} alt="Freight services" />
          </div>
        </div>
        <div className="aboutus-sections-wrapper">
          <div className="aboutus-section-2">
            <div className="aboutus-text-content-2" ref={textContent2Ref}>
              <h2>History of the Directors</h2>
              <p>
                Chris and John first met over a decade ago while working at a local transport company. Their shared passion for the logistics industry and a mutual desire to create something of their own led them to form a strong professional bond. With their combined extensive experience in local, domestic, and international freight, they decided to embark on a journey to start their own transport business. Their deep understanding of the industry and shared vision laid the foundation for what would become Quan Freight Management.
              </p>
            </div>
          </div>
          <div className="aboutus-section-3">
            <div className="aboutus-text-content-3" ref={textContent3Ref}>
              <h2>Our Offer to You</h2>
              <h3>Your shipment is always our first priority</h3>
              <ul>
                <li>Reliable and timely delivery across Australia</li>
                <li>Comprehensive logistics solutions tailored to your needs</li>
                <li>State-of-the-art technology for efficient tracking</li>
                <li>Cost-effective transport options to optimize your budget</li>
                <li>Experienced and professional team dedicated to excellence</li>
              </ul>
            </div>
            <Link to="/contactus" className="aboutus-linkz">
              <div className="aboutus-request-quote" ref={requestQuoteRef}>
                Request Quote
              </div>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
