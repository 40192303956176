import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom'; // Import useLocation for route changes
import '../css/MobileHeader.css';
import imagelogo from '../images/qfmlogo.png';

function MobileHeader() {
    const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false); // Updated constant name
    const [isMobileScrolled, setIsMobileScrolled] = useState(false); // Updated constant name
    const mobileDropdownRef = useRef(null); // Updated ref name
    const mobileLocation = useLocation(); // Updated constant name

    // Toggle Mobile Dropdown
    const toggleMobileDropdown = () => {
        setIsMobileDropdownOpen((prev) => !prev);
    };

    // Close Mobile Dropdown
    const closeMobileDropdown = () => {
        setIsMobileDropdownOpen(false);
    };

    // Handle clicks outside the mobile dropdown
    const handleMobileClickOutside = (event) => {
        if (mobileDropdownRef.current && !mobileDropdownRef.current.contains(event.target)) {
            closeMobileDropdown();
        }
    };

    // Detect scrolling to add shadow to the mobile header
    const handleMobileScroll = () => {
        if (window.scrollY > 50) {
            setIsMobileScrolled(true);
        } else {
            setIsMobileScrolled(false);
        }
    };

    // Refresh page when clicking the logo
    const handleLogoClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        window.location.reload(); // Refresh the page
    };

    // Add event listener for clicking outside and scrolling
    useEffect(() => {
        if (isMobileDropdownOpen) {
            document.addEventListener('mousedown', handleMobileClickOutside);
        } else {
            document.removeEventListener('mousedown', handleMobileClickOutside);
        }

        window.addEventListener('scroll', handleMobileScroll);
        return () => {
            document.removeEventListener('mousedown', handleMobileClickOutside);
            window.removeEventListener('scroll', handleMobileScroll);
        };
    }, [isMobileDropdownOpen]);

    // Automatically close mobile dropdown when the route changes
    useEffect(() => {
        closeMobileDropdown(); // Close mobile dropdown on route change
    }, [mobileLocation]); // location changes whenever the URL changes

    return (
        <header className={`Mobile-header ${isMobileScrolled ? 'scrolled' : ''}`}>
            <div className="mobile-header-container">
                <div className="mobile-header-section-1">
                    <Link to="/" className="logo-link" onClick={handleLogoClick}>
                        <img src={imagelogo} alt="Logo" />
                    </Link>
                </div>
                <div className="mobile-header-section-2">
                    <a
                        href="https://qfmlogistics.machship.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="login-link"
                    >
                        Portal
                    </a>
                </div>
            </div>
        </header>
    );
}

export default MobileHeader;
