import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom'; // Import NavLink from react-router-dom
import '../css/Header.css';
import imagelogo from '../images/qfmlogo.png';

function Header({ isScrolledInitially }) {
    const [isScrolled, setIsScrolled] = useState(isScrolledInitially);

    // Handle scrolling event to set the isScrolled state dynamically
    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        // Add scroll listener only if the header should start unscrolled (main page)
        if (!isScrolledInitially) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isScrolledInitially]);

    return (
        <header className={`App-header ${isScrolled ? 'scrolled' : ''}`}>
            <div className="header-container">
                <div className="header-section-1">
                    <Link to="/" className="logo-link">
                        <img src={imagelogo} alt="Logo" />
                    </Link>
                </div>
                <div className="header-section-2">
                    <nav>
                        <ul className="header-nav-links">
                            {isScrolled && (
                                <li>
                                    <NavLink exact to="/" activeClassName="active">
                                        Home
                                        <span className="border-top-left"></span>
                                        <span className="border-top-right"></span>
                                        <span className="border-right"></span>
                                        <span className="border-bottom"></span>
                                        <span className="border-left"></span>
                                        <span className="background-fill"></span>
                                    </NavLink>
                                </li>
                            )}
                            <li>
                                <NavLink to="/aboutus" activeClassName="active">
                                    About Us
                                    <span className="border-top-left"></span>
                                    <span className="border-top-right"></span>
                                    <span className="border-right"></span>
                                    <span className="border-bottom"></span>
                                    <span className="border-left"></span>
                                    <span className="background-fill"></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/services" activeClassName="active">
                                    Services
                                    <span className="border-top-left"></span>
                                    <span className="border-top-right"></span>
                                    <span className="border-right"></span>
                                    <span className="border-bottom"></span>
                                    <span className="border-left"></span>
                                    <span className="background-fill"></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/technology" activeClassName="active">
                                    Technology
                                    <span className="border-top-left"></span>
                                    <span className="border-top-right"></span>
                                    <span className="border-right"></span>
                                    <span className="border-bottom"></span>
                                    <span className="border-left"></span>
                                    <span className="background-fill"></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/contactus" activeClassName="active">
                                    Contact Us
                                    <span className="border-top-left"></span>
                                    <span className="border-top-right"></span>
                                    <span className="border-right"></span>
                                    <span className="border-bottom"></span>
                                    <span className="border-left"></span>
                                    <span className="background-fill"></span>
                                </NavLink>
                            </li>
                            <li>
                                <a href="https://qfmlogistics.machship.com/" target="_blank" rel="noopener noreferrer">
                                    Customer Portal
                                    <span className="border-top-left"></span>
                                    <span className="border-top-right"></span>
                                    <span className="border-right"></span>
                                    <span className="border-bottom"></span>
                                    <span className="border-left"></span>
                                    <span className="background-fill"></span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;
