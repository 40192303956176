import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/CustomerPortal.css';
// import { Link } from 'react-router-dom';

function CustomerPortal() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);

  return (
    <div className="aboutus-main-content">
      <div className="aboutus-content-container">
        <Header isScrolledInitially={true} />
        <div className="aboutus-vertical-line"></div>
        <div className="aboutus-section-1">
          <div className="aboutus-text-content">
            <h3>Customer Portal - Under Development</h3>
            <p>
              
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CustomerPortal;
