import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MainContent from './components/MainContent';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import Technology from './components/Technology';
import DevelopmentMessage from './components/DevelopmentMessage';
import CustomerPortal from './components/CustomerPortal';
// import Header from './components/Header';
// import Footer from './components/Footer';
import MobileHeader from './components/MobileHeader';
import MobileMainContent from './components/MobileMainContent';
import MobileFooter from './components/MobileFooter';
import MobileAboutUs from './components/MobileAboutUs.js';
import MobileServices from './components/MobileServices.js';
import MobileTechnology from './components/MobileTechnology.js';
import MobileContactUs from './components/MobileContactUs.js';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Check for mobile screen size (768px and below)
    };

    checkIfMobile(); // Run on initial load

    const handleResize = () => {
      checkIfMobile(); // Recheck on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Render MobileHeader or Header based on isMobile state */}
        {isMobile ? <MobileHeader /> : null}
        
        <div className="App-content">
          <Routes>
            {/* Render mobile or desktop components based on isMobile state */}
            {isMobile ? (
              <>
                <Route path="/" element={<MobileMainContent />} />
                {/* <Route path="/aboutus" element={<MobileAboutUs />} />
                <Route path="/services" element={<MobileServices />} />
                <Route path="/technology" element={<MobileTechnology />} />
                <Route path="/contactus" element={<MobileContactUs />} /> */}
              </>
            ) : (
              <>
                <Route path="/" element={<MainContent />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/technology" element={<Technology />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/developmentmessage" element={<DevelopmentMessage />} />
                <Route path="/customerportal" element={<CustomerPortal />} />
              </>
            )}
          </Routes>
        </div>

        {/* Render Footer or MobileFooter based on isMobile state */}
        {/* {isMobile ? <MobileFooter /> : null} */}
      </div>
    </Router>
  );
}

export default App;