import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../css/MainContent.css';
import '../css/ContactUs.css';
import CustomAlert from './CustomAlert';
import Header from './Header';
import Footer from './Footer';
import emailjs from 'emailjs-com';
// import yourImage4 from '../images/truck-lake.webp';
import yourImage4 from '../images/QFM_Landing_Max.jpg';
import MImage1 from '../images/fmservice.webp';
import ImageCard1 from '../images/truckimage.webp';
import ImageCard2 from '../images/planeimage.webp';
import ImageCard3 from '../images/courierimage.webp';
import ImageCard4 from '../images/userfriendlyimage.webp';
import InfoContainer1 from '../images/problemsolving.png';
import InfoContainer2 from '../images/education.png';
import InfoContainer3 from '../images/target.png';
const images = [yourImage4];


function MainContent() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const intervalRef = useRef(null);
    const carouselContainerRef = useRef(null);
    const isAutoScrollingRef = useRef(false);
    const hasScrolledToBottomRef = useRef(false);
    const observerRef = useRef(null);
    const sectionsRef = useRef([]);
    // const textContentRef = useRef(null);
    const imageContainerRef = useRef(null);
    // const textContent2Ref = useRef(null);
    // const requestQuoteRef = useRef(null);
    // const textContent3Ref = useRef(null);
    // const textContentRef9 = useRef(null);

    const handleArrowClick = () => {
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;
        window.scrollTo({
            top: imageBottomPosition,
            behavior: 'smooth',
        });
    };

    const [selectedForm, setSelectedForm] = useState('information');
    const [quoteStep, setQuoteStep] = useState(1);
    const [formErrors, setFormErrors] = useState({});
    const [formValues, setFormValues] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        remarks: '',
    });

    const [quoteFormValues, setQuoteFormValues] = useState({
        name: '',
        company: '',
        address: '',
        email: '',
        phone: '',
        pickupDestination: '',
        deliveryLocation: '',
        itemType: '',
        weight: '',
        quantity: '',
        length: '',
        width: '',
        height: '',
    });

    const [quoteFormErrors, setQuoteFormErrors] = useState({
        name: '',
        company: '',
        address: '',
        email: '',
        phone: '',
        pickupDestination: '',
        deliveryLocation: '',
        itemType: '',
        weight: '',
        quantity: '',
        length: '',
        width: '',
        height: '',
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleRadioChange = (event) => {
        setSelectedForm(event.target.value);
        setQuoteStep(1);
    };

    const handlePreviousStep = (event) => {
        event.preventDefault();
        setQuoteStep((prevStep) => prevStep - 1);
    };

    const handleInputFocus = (event) => {
        const { name } = event.target;
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleQuoteInputFocus = (event) => {
        const { name } = event.target;
        setQuoteFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'remarks' && value.length > 279) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                remarks: 'Maximum 280 Characters',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleQuoteInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        setQuoteFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setQuoteFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    const validateQuoteStep1 = () => {
        const errors = {};
        if (!quoteFormValues.name) errors.name = 'Required';
        if (!quoteFormValues.company) errors.company = 'Required';
        if (!quoteFormValues.address) errors.address = 'Required';
        if (!quoteFormValues.email) {
            errors.email = 'Required';
        } else if (!validateEmail(quoteFormValues.email)) {
            errors.email = 'Must be a valid email';
        }
        if (!quoteFormValues.phone) {
            errors.phone = 'Required';
        } else if (!validatePhone(quoteFormValues.phone)) {
            errors.phone = 'Must be a valid number';
        }
        setQuoteFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleQuoteNextStep = (event) => {
        event.preventDefault();
        if (quoteStep === 1) {
            if (validateQuoteStep1()) {
                setQuoteStep((prevStep) => prevStep + 1);
            } else {
                console.log('Step 1 validation failed:', quoteFormErrors);
            }
        } else if (quoteStep === 2) {
            if (validateQuoteStep2()) {
                setQuoteStep((prevStep) => prevStep + 1);
            } else {
                console.log('Step 2 validation failed:', quoteFormErrors);
            }
        }
    };

    const validateQuoteStep2 = () => {
        const errors = {};
        if (!quoteFormValues.pickupDestination) errors.pickupDestination = 'Required';
        if (!quoteFormValues.deliveryLocation) errors.deliveryLocation = 'Required';
        if (!quoteFormValues.itemType) errors.itemType = 'Required';
        if (!quoteFormValues.weight) {
            errors.weight = 'Required';
        } else if (isNaN(quoteFormValues.weight)) {
            errors.weight = 'Must be a valid number';
        }
        if (!quoteFormValues.quantity) {
            errors.quantity = 'Required';
        } else if (isNaN(quoteFormValues.quantity)) {
            errors.quantity = 'Must be a valid number';
        }
        if (!quoteFormValues.length) {
            errors.length = 'Required';
        } else if (isNaN(quoteFormValues.length)) {
            errors.length = 'Must be a valid number';
        }
        if (!quoteFormValues.width) {
            errors.width = 'Required';
        } else if (isNaN(quoteFormValues.width)) {
            errors.width = 'Must be a valid number';
        }
        if (!quoteFormValues.height) {
            errors.height = 'Required';
        } else if (isNaN(quoteFormValues.height)) {
            errors.height = 'Must be a valid number';
        }
        setQuoteFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^\d+$/;
        return phonePattern.test(phone);
    };

    const validateForm = () => {
        const errors = {};
        if (!formValues.name) errors.name = 'Required';
        if (!formValues.company) errors.company = 'Required';
        if (!formValues.email) {
            errors.email = 'Required';
        } else if (!validateEmail(formValues.email)) {
            errors.email = 'Must be a valid email';
        }
        if (!formValues.phone) {
            errors.phone = 'Required';
        } else if (!validatePhone(formValues.phone)) {
            errors.phone = 'Must be a valid number';
        }
        return errors;
    };

    const handleScrollDown = () => {
        if (isAutoScrollingRef.current) return;

        const scrollY = window.scrollY;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 68;

        if (scrollY > 1 && !hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({
                top: imageBottomPosition,
                behavior: 'smooth'
            });
            hasScrolledToBottomRef.current = true;

            // Disable scrolling
            document.body.style.overflow = 'hidden';

            // Re-enable scrolling after 1 second
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1200);
        }
    };

    const handleScrollUp = () => {
        if (isAutoScrollingRef.current) return;

        const scrollY = window.scrollY;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;

        if (scrollY < imageBottomPosition && hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            hasScrolledToBottomRef.current = false;

            // Disable scrolling
            document.body.style.overflow = 'auto';

            // Re-enable scrolling after 1 second
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1000);
        }
    };

    const toggleSectionVisibility = () => {
        const scrollY = window.scrollY;
        const sections = sectionsRef.current;

        if (scrollY === 0) {
            sections.forEach((section) => {
                section.classList.remove('visible');
                section.classList.add('hidden');
            });

            // Reset the observer
            if (observerRef.current) {
                observerRef.current.disconnect();
                sections.forEach(section => {
                    observerRef.current.observe(section);
                });
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            handleScrollDown();
            handleScrollUp();
            toggleSectionVisibility();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.50 // Set the threshold to 40%
        };

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry, index) => {
                if (entry.isIntersecting) {
                    entry.target.style.animationDelay = `${index * 1}s`; // Delay each image card by 1 second
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        const sections = document.querySelectorAll('.image-container, .maincontentwrapper, .contactus-section-2 maincontentcontact, .maincontentone-div-one, .maincontentone-div-two, .maincontent-section-four, .maincontentaboutus-image-container, .maincontentaboutus-link-container, .maincontentaboutus-text-content h1, .maincontentaboutus-text-content p, .new-section, .new-section-2, .image-card, .circle-container, .info-container, .new-section-3');
        sectionsRef.current = sections;
        sections.forEach(section => {
            observerRef.current.observe(section);
        });

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        const updateCarouselHeight = () => {
            const header = document.querySelector('.App-header');
            const carouselHeight = window.innerHeight - (header ? header.offsetHeight : 0);
            document.documentElement.style.setProperty('--carousel-height', `${carouselHeight}px`);
        };

        updateCarouselHeight();
        window.addEventListener('resize', updateCarouselHeight);

        return () => {
            window.removeEventListener('resize', updateCarouselHeight);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const startImageTimer = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 7000);
    };

    useEffect(() => {
        startImageTimer();

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        let rotation = 0;
        let ticking = false;
        let circleTop = 0;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const scrollTop = window.scrollY;
                    const circleElement = document.querySelector('.circle');
                    if (circleElement) {
                        const circleVisible = scrollTop + window.innerHeight - circleTop;
                        if (circleVisible) {
                            rotation = Math.min((scrollTop - circleTop + window.innerHeight) / 2, 540);
                            circleElement.style.setProperty('--rotation', `${rotation}deg`);
                        }
                    }
                    ticking = false;
                });
                ticking = true;
            }
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const rect = entry.target.getBoundingClientRect();
                    circleTop = rect.top + window.scrollY;
                    window.addEventListener('scroll', handleScroll);
                } else {
                    window.removeEventListener('scroll', handleScroll);
                }
            });
        }, { threshold: 0.1 });

        const circleElement = document.querySelector('.circle');
        if (circleElement) {
            observer.observe(circleElement);
        }

        return () => {
            if (circleElement) {
                observer.unobserve(circleElement);
            }
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Initial check
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(''); // 'success' or 'error'




    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            const templateParams = {
                name: formValues.name,
                company: formValues.company,
                email: formValues.email,
                phone: formValues.phone,
                remarks: formValues.remarks,
            };

            emailjs.send('service_eqjos09', 'template_6v12fmu', templateParams, '6hHglwaeH0hQQmjcJ')
                .then(() => {
                    setAlertTitle('Success');
                    setAlertMessage('Thank you! Your information request has been submitted.');
                    setAlertType('success');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 4000);
                    setFormValues({
                        name: '',
                        company: '',
                        email: '',
                        phone: '',
                        remarks: '',
                    });
                })
                .catch(() => {
                    setAlertTitle('Error');
                    setAlertMessage('There was an issue submitting your request. Please try again later.');
                    setAlertType('error');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 4000);
                });
        }
    };


    const handleQuoteSubmit = (event) => {
        event.preventDefault();
        const errors = validateQuoteStep1(); // Add other steps validation as needed
        setQuoteFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const quoteTemplateParams = {
                name: quoteFormValues.name,
                company: quoteFormValues.company,
                address: quoteFormValues.address,
                email: quoteFormValues.email,
                phone: quoteFormValues.phone,
                pickupDestination: quoteFormValues.pickupDestination,
                deliveryLocation: quoteFormValues.deliveryLocation,
                itemType: quoteFormValues.itemType,
                weight: quoteFormValues.weight,
                quantity: quoteFormValues.quantity,
                length: quoteFormValues.length,
                width: quoteFormValues.width,
                height: quoteFormValues.height,
                hydraulicTailgate: quoteFormValues.hydraulicTailgate ? 'Yes' : 'No',
                residentialDelivery: quoteFormValues.residentialDelivery ? 'Yes' : 'No',
                poBoxDelivery: quoteFormValues.poBoxDelivery ? 'Yes' : 'No',
                residentialPickup: quoteFormValues.residentialPickup ? 'Yes' : 'No',
            };

            emailjs.send('service_eqjos09', 'template_1bmxjm7', quoteTemplateParams, '6hHglwaeH0hQQmjcJ')
                .then(() => {
                    setAlertTitle('Success');
                    setAlertType('success');
                    setAlertMessage('Thank you! Your quote request has been submitted.');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 4000);

                    setQuoteFormValues({
                        name: '',
                        company: '',
                        address: '',
                        email: '',
                        phone: '',
                        pickupDestination: '',
                        deliveryLocation: '',
                        itemType: '',
                        weight: '',
                        quantity: '',
                        length: '',
                        width: '',
                        height: '',
                        hydraulicTailgate: false,
                        residentialDelivery: false,
                        poBoxDelivery: false,
                        residentialPickup: false,
                    });

                    // Optionally, you can also clear form errors
                    setQuoteFormErrors({});
                    setQuoteStep(1);
                })
                .catch(() => {
                    setAlertTitle('Error');
                    setAlertType('error');
                    setAlertMessage('There was an issue submitting your quote request. Please try again later.');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 4000);
                });
        } else {
            setAlertTitle('Error');
            setAlertType('error');
            setAlertMessage('Please correct the errors in the form and try again.');
            setAlertVisible(true);
            setTimeout(() => setAlertVisible(false), 4000);
        }
    };





    return (
        <div className="maincontent">
            {alertVisible && (
                <CustomAlert
                    title={alertTitle}
                    message={alertMessage}
                    type={alertType}
                    onClose={() => setAlertVisible(false)}
                />
            )} 
            <Header />
            <div className="maincontent-one">
                <div className="carousel-container" ref={carouselContainerRef}>
                    <div className="carousel-arrow-down" onClick={handleArrowClick}>
                        <i className="fas fa-chevron-down"></i>
                    </div>

                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt="Main Content"
                            className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`}
                        />
                    ))}
                </div>
            </div>
            <div className="maincontent-two">
                <div className="maincontent-vertical-line"></div>
                <div className="maincontent-section-seven">
                    <div className="maincontentaboutus">
                        <div className="maincontentaboutus-section-1">
                            <div className="maincontentaboutus-text-content">
                                <h1>Quan Freight Management</h1>
                                <p>Quan Freight Management (QFM) is a premier, Australian owned and operated logistics company specializing in domestic freight across Australia, whether by air, sea, rail, or road. We provide comprehensive, integrated door-to-door logistics solutions, including land transport, distribution, and value-added services.</p>
                                <div className="maincontentaboutus-link-container">
                                    <Link to="/aboutus" className="maincontentaboutus-findoutmore">Find out more</Link>
                                </div>
                            </div>
                            <div className="maincontentaboutus-image-container" ref={imageContainerRef}>
                                <img src={MImage1} alt="About Us" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="maincontent-section-two">
                    <div className="maincontent-separation-line-top"></div>
                    <div className="image-card" style={{ cursor: 'pointer' }}>
                        <div className="image-container">
                            <Link to="/services">
                                <div class="filter-overlay"></div>
                                <img src={ImageCard1} alt="Interstate Freight" />
                                <div className="imagecard-overlay-div">
                                    <h2>Interstate Freight</h2>
                                    <p>At QFM, we offer access to an extensive network of carriers, providing customized interstate freight solutions to meet your unique needs.</p>
                                    <Link to="/services" className="learn-more-link">
                                        Learn more →
                                    </Link>
                                </div>
                                <div className="imagecard-overlay-divtwo">
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="image-card" style={{ cursor: 'pointer' }}>
                        <div className="image-container">
                            <Link to="/services">
                                <div class="filter-overlay"></div>
                                <img src={ImageCard2} alt="Air Freight" />
                                <div className="imagecard-overlay-div">
                                    <h2>Air Freight</h2>
                                    <p>Offering overnight nationwide service across most areas of Australia using air freight. Everything from satchels and cartons to small pallets. </p>
                                    <Link to="/services" className="learn-more-link">
                                        Learn more →
                                    </Link>
                                </div>
                                <div className="imagecard-overlay-divtwo">
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="image-card" style={{ cursor: 'pointer' }}>
                        <div className="image-container">
                            <Link to="/services">
                                <div class="filter-overlay"></div>
                                <img src={ImageCard3} alt="Local Freight" />
                                <div className="imagecard-overlay-div">
                                    <h2>Local Freight</h2>
                                    <p>Reliable local transport solutions for both ad-hoc movements and permanent vehicle arrangements, providing access to trusted courier companies.</p>
                                    <Link to="/services" className="learn-more-link">
                                        Learn more →
                                    </Link>
                                </div>
                                <div className="imagecard-overlay-divtwo">
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="image-card" style={{ cursor: 'pointer' }}>
                        <div className="image-container">
                            <Link to="/technology">
                                <div class="filter-overlay"></div>
                                <img src={ImageCard4} alt="Technology" />
                                <div className="imagecard-overlay-div">
                                    <h2>Technology</h2>
                                    <p>Utilizing advanced technology to optimize our services, providing real-time tracking and seamless communication for complete visibility.</p>
                                    <Link to="/technology" className="learn-more-link">
                                        Learn more →
                                    </Link>
                                </div>
                                <div className="imagecard-overlay-divtwo">
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="maincontent-section-three">
                    <div className="maincontent-separation-line-top"></div>
                    <div className="circle-container">
                        <div className="circle">
                            <h1>Grow your business with leading Technology and a Reliable Supportive Team</h1>
                        </div>
                    </div>
                    <div className="maincontent-info-container">
                        <div className="info-container">
                            <img src={InfoContainer1} alt="Icon 1" className="info-icon" />
                            <div>
                                <h2>Our Mission</h2>
                                <p>Our mission is to deliver peace of mind with reliable, high-quality solutions at competitive prices. Our flexibility allows us to adapt swiftly to customer needs, ensuring efficient operations that lower costs for our clients.</p>
                            </div>
                        </div>
                        <div className="info-container">
                            <img src={InfoContainer2} alt="Icon 2" className="info-icon" />
                            <div>
                                <h2>Expert Knowledge</h2>
                                <p>With years of experience, our expertise has earned the trust and recognition of our customers. We are committed to delivering high-quality service, consistently exceeding expectations and building strong, lasting relationships.</p>
                            </div>
                        </div>
                        <div className="info-container">
                            <img src={InfoContainer3} alt="Icon 3" className="info-icon" />
                            <div>
                                <h2>Our Vision</h2>
                                <p>Our vision is not to become the biggest, but to become the best. We focus on innovation while prioritizing our environment and community. We treat our customers, partners, and suppliers with the respect we’d expect ourselves. </p>
                            </div>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
                <div className="maincontent-section-four">
                    <h2>Arrange an audit of your Logistics</h2>
                    <Link to="/contactus" className="maincontent-contact-button">Contact Us</Link>
                </div>
                <div className="maincontent-section-one">
                    <div className="maincontentone-div-one">
                        <h2>Simplified Logistics</h2>
                        <p>Our expert team employs a centralized approach to develop and oversee comprehensive supply chain solutions. By leveraging state-of-the-art technology, we enhance visibility, reduce waste, mitigate risks, and drive cost savings in the short, medium, and long term.</p>
                    </div>
                    <div className="maincontent-separation-line-section-one"></div>
                    <div className="maincontentone-div-two">
                        <h2>Efficient Operations</h2>
                        <p>Our logistics services are designed to optimize operations, ensuring timely delivery and reduced operational costs. Our team uses innovative strategies to streamline processes and improve overall efficiency.</p>
                    </div>
                </div>
                <div className="maincontent-section-five">
                    <div className="maincontentwrapper">
                        <div className="updatecolourcontactus">
                            <h2>Get in touch today</h2>
                            <p>For advice, quotes and all your questions, we are available to help at any time.</p>
                            <div className="updatecolourcontactus-contactus-separation-line"></div>
                            <p><i className="fas fa-envelope"></i> contact@qfmlogistics.com.au</p>
                        </div>
                        <div className="maincontentcontact">
                            {selectedForm === 'information' ? (
                                <form className="contact-us-form" onSubmit={handleSubmit}>
                                    <div className="form-row center-row">
                                        <div className="form-group radio-group">
                                            <label>
                                                <input type="radio" id="requestInformation" name="requestType" value="information" defaultChecked onChange={handleRadioChange} />
                                                Request Information
                                            </label>
                                        </div>
                                        <div className="form-group radio-group">
                                            <label>
                                                <input type="radio" id="requestQuote" name="requestType" value="quote" onChange={handleRadioChange} />
                                                Request Quote
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label htmlFor="name">Name {formErrors.name && <span className="label-error">{formErrors.name}</span>}</label>
                                            <input type="text" id="name" name="name" value={formValues.name} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.name ? 'input-error' : ''} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="company">Company {formErrors.company && <span className="label-error">{formErrors.company}</span>}</label>
                                            <input type="text" id="company" name="company" value={formValues.company} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.company ? 'input-error' : ''} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label htmlFor="email">Email {formErrors.email && <span className="label-error">{formErrors.email}</span>}</label>
                                            <input type="email" id="email" name="email" value={formValues.email} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.email ? 'input-error' : ''} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">Phone {formErrors.phone && <span className="label-error">{formErrors.phone}</span>}</label>
                                            <input type="tel" id="phone" name="phone" value={formValues.phone} onChange={handleInputChange} onFocus={handleInputFocus} className={formErrors.phone ? 'input-error' : ''} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group full-width">
                                            <label htmlFor="remarks">Remarks {formErrors.remarks && <span className="error">{formErrors.remarks}</span>}</label>
                                            <textarea
                                                id="remarks"
                                                name="remarks"
                                                rows="4"
                                                maxLength="280"
                                                value={formValues.remarks}
                                                onChange={handleInputChange}
                                                style={{ resize: 'none' }}  // Prevent textarea resizing
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="response-time">
                                        We will respond within one business day.
                                    </div>
                                    <div className="button-row right-aligned">
                                        <button type="submit" className="send-button">Send</button>
                                    </div>
                                </form>
                            ) : (
                                quoteStep === 1 ? (
                                    <form className="contact-us-form">
                                        <div className="form-row center-row">
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                                                    Request Information
                                                </label>
                                            </div>
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                                                    Request Quote
                                                </label>
                                            </div>
                                        </div>
                                        <div className="step-indicator">
                                            Step 1 of 3 - Personal information
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label htmlFor="name">Name {quoteFormErrors.name && <span className="label-error">{quoteFormErrors.name}</span>}</label>
                                                <input type="text" id="name" name="name" value={quoteFormValues.name} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.name ? 'input-error' : ''} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="company">Company {quoteFormErrors.company && <span className="label-error">{quoteFormErrors.company}</span>}</label>
                                                <input type="text" id="company" name="company" value={quoteFormValues.company} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.company ? 'input-error' : ''} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group full-width">
                                                <label htmlFor="address">Address {quoteFormErrors.address && <span className="label-error">{quoteFormErrors.address}</span>}</label>
                                                <input type="text" id="address" name="address" value={quoteFormValues.address} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.address ? 'input-error' : ''} />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label htmlFor="email">Email {quoteFormErrors.email && <span className="label-error">{quoteFormErrors.email}</span>}</label>
                                                <input type="email" id="email" name="email" value={quoteFormValues.email} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.email ? 'input-error' : ''} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="phone">Phone {quoteFormErrors.phone && <span className="label-error">{quoteFormErrors.phone}</span>}</label>
                                                <input type="tel" id="phone" name="phone" value={quoteFormValues.phone} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.phone ? 'input-error' : ''} />
                                            </div>
                                        </div>
                                        <div className="button-row">
                                            <button type="button" className="next-button right-aligned" onClick={handleQuoteNextStep}>Next</button>
                                        </div>
                                    </form>
                                ) : quoteStep === 2 ? (
                                    <form className="contact-us-form">
                                        <div className="form-row center-row">
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                                                    Request Information
                                                </label>
                                            </div>
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                                                    Request Quote
                                                </label>
                                            </div>
                                        </div>
                                        <div className="step-indicator">
                                            Step 2 of 3 - Quote details
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label htmlFor="pickupDestination">Pickup Destination {quoteFormErrors.pickupDestination && <span className="label-error">{quoteFormErrors.pickupDestination}</span>}</label>
                                                <input type="text" id="pickupDestination" name="pickupDestination" value={quoteFormValues.pickupDestination} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.pickupDestination ? 'input-error' : ''} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="deliveryLocation">Delivery Location {quoteFormErrors.deliveryLocation && <span className="label-error">{quoteFormErrors.deliveryLocation}</span>}</label>
                                                <input type="text" id="deliveryLocation" name="deliveryLocation" value={quoteFormValues.deliveryLocation} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.deliveryLocation ? 'input-error' : ''} />
                                            </div>
                                        </div>
                                        <div className="form-row three-items">
                                            <div className="form-group">
                                                <label htmlFor="itemType">Item Type {quoteFormErrors.itemType && <span className="label-error">{quoteFormErrors.itemType}</span>}</label>
                                                <select id="itemType" name="itemType" value={quoteFormValues.itemType} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.itemType ? 'input-error' : ''}>
                                                    <option value="">Item type</option>
                                                    <option value="Envelope">Envelope</option>
                                                    <option value="carton">Carton</option>
                                                    <option value="skid">Skid</option>
                                                    <option value="pallet">Pallet</option>
                                                    <option value="crate">Crate</option>
                                                    <option value="drum">Barrel/Drum</option>
                                                    <option value="bulkbag">Bulk Bags</option>
                                                    <option value="container">Container</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="weight">Weight {quoteFormErrors.weight && <span className="label-error">{quoteFormErrors.weight}</span>}</label>
                                                <input type="number" id="weight" name="weight" value={quoteFormValues.weight} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.weight ? 'input-error' : ''} placeholder="kg" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="quantity">Quantity {quoteFormErrors.quantity && <span className="label-error">{quoteFormErrors.quantity}</span>}</label>
                                                <input type="number" id="quantity" name="quantity" value={quoteFormValues.quantity} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.quantity ? 'input-error' : ''} />
                                            </div>
                                        </div>
                                        <div className="form-row three-items">
                                            <div className="form-group">
                                                <label htmlFor="length">Length {quoteFormErrors.length && <span className="label-error">{quoteFormErrors.length}</span>}</label>
                                                <input type="number" id="length" name="length" value={quoteFormValues.length} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.length ? 'input-error' : ''} placeholder="cm" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="width">Width {quoteFormErrors.width && <span className="label-error">{quoteFormErrors.width}</span>}</label>
                                                <input type="number" id="width" name="width" value={quoteFormValues.width} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.width ? 'input-error' : ''} placeholder="cm" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="height">Height {quoteFormErrors.height && <span className="label-error">{quoteFormErrors.height}</span>}</label>
                                                <input type="number" id="height" name="height" value={quoteFormValues.height} onChange={handleQuoteInputChange} onFocus={handleQuoteInputFocus} className={quoteFormErrors.height ? 'input-error' : ''} placeholder="cm" />
                                            </div>
                                        </div>
                                        <div className="button-row">
                                            <button type="button" className="previous-button" onClick={handlePreviousStep}>Previous</button>
                                            <button type="button" className="next-button" onClick={handleQuoteNextStep}>Next</button>
                                        </div>
                                    </form>
                                ) : (
                                    <form className="contact-us-form" onSubmit={handleQuoteSubmit}>
                                        <div className="form-row center-row">
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestInformation" name="requestType" value="information" onChange={handleRadioChange} />
                                                    Request Information
                                                </label>
                                            </div>
                                            <div className="form-group radio-group">
                                                <label>
                                                    <input type="radio" id="requestQuote" name="requestType" value="quote" defaultChecked onChange={handleRadioChange} />
                                                    Request Quote
                                                </label>
                                            </div>
                                        </div>
                                        <div className="step-indicator">
                                            Step 3 of 3 - Additional details
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="hydraulicTailgate"
                                                        name="hydraulicTailgate"
                                                        checked={quoteFormValues.hydraulicTailgate}
                                                        onChange={handleQuoteInputChange}
                                                    />
                                                    Hydraulic Tailgate required
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="residentialDelivery"
                                                        name="residentialDelivery"
                                                        checked={quoteFormValues.residentialDelivery}
                                                        onChange={handleQuoteInputChange}
                                                    />
                                                    Is this a residential delivery?
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="poBoxDelivery"
                                                        name="poBoxDelivery"
                                                        checked={quoteFormValues.poBoxDelivery}
                                                        onChange={handleQuoteInputChange}
                                                    />
                                                    Is this a PO Box Delivery?
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="residentialPickup"
                                                        name="residentialPickup"
                                                        checked={quoteFormValues.residentialPickup}
                                                        onChange={handleQuoteInputChange}
                                                    />
                                                    Is this a residential pickup?
                                                </label>
                                            </div>
                                        </div>

                                        <div className="button-row">
                                            <button type="button" className="previous-button" onClick={handlePreviousStep}>Previous</button>
                                            <button type="submit" className="submit-button">Submit</button>
                                        </div>
                                    </form>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MainContent;

