import React from 'react';

function MobileFooter() {
  return (
    <footer className="Mobile-footer">
      <p>&copy; 2024 Quan Freight Management Pty Ltd. All rights reserved.</p>
    </footer>
  );
}

export default MobileFooter;
