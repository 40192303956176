import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/Services.css'; // Updated from AboutUs.css
import { Link } from 'react-router-dom';
import Image2 from '../images/completeservices.webp';

// import Item1 from '../images/envelope.png';
// import Item2 from '../images/carton.png';
// import Item3 from '../images/skid.png';
// import Item4 from '../images/pallet.png';
// import Item5 from '../images/crate.png';
// import Item6 from '../images/drum.png';
// import Item7 from '../images/container.png';
// import Item8 from '../images/roadrailair.png';

import Service1 from '../images/servicesroadfreight.webp';
import Service2 from '../images/servicesrailfreight.webp';
import Service3 from '../images/servicesairfreight.webp';
import Service4 from '../images/serviceslocalfreight.webp';
import Service5 from '../images/servicesprojectfreight.webp';
import Service6 from '../images/servicesdangerousgoods.webp';

function Services() {
  window.scrollTo(0, 0);

  const serviceTextContentRef = useRef(null);
  const serviceImageContainerRef = useRef(null);
  const serviceTextContent2Ref = useRef(null);
  const serviceTextContentH2Ref = useRef(null);
  const serviceDetailRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.35, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    }, observerOptions);

    const elementsToObserve = [
      serviceTextContentRef.current,
      serviceImageContainerRef.current,
      serviceTextContent2Ref.current,
      serviceTextContentH2Ref.current,
      ...serviceDetailRefs.current,
    ];

    elementsToObserve.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, []);

  return (
    <div className="services-main-content">
      <div className="services-content-container">
        <Header isScrolledInitially={true} />
        <div className="services-vertical-line"></div>
        <div className="services-section-1">
          <div className="services-text-content" ref={serviceTextContentRef}>
            <h1>Our Services</h1>
            <p>
              At QFM, we offer a comprehensive range of logistics and transportation services tailored to meet the unique needs of our clients. Whether you're shipping locally or domestically, our team of experienced professionals ensures that your goods are handled with the utmost care and delivered on time.
            </p>
          </div>
          <div className="services-image-container">
            <img src={Image2} alt="Services" />
          </div>
        </div>

        
        <div className="services-sections-wrapper">
          <div className="services-section-2">
            <div className="services-text-content-2" ref={serviceTextContent2Ref}>
              <h2 ref={serviceTextContentH2Ref}>Overview of Our Logistics Solutions</h2>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[0] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service1} alt="Road Freight" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Road Freight</h3>
                    <p>
                      Our Road Freight services are designed to offer reliable and flexible transportation solutions across the country. Whether you need to move small parcels or large shipments, our extensive network of carriers ensures that your goods are delivered safely and on time. We tailor our services to meet the unique needs of each client, providing a seamless and cost-effective solution for all your road transportation requirements.
                    </p>
                    <ul>
                      <li><b>Extensive Carrier Network:</b> Access to a wide range of carriers to ensure timely delivery across regions.</li>
                      <li><b>Customizable Solutions:</b> Tailored transport plans that suit the specific needs of your business, from small parcels to large freight.</li>
                      <li><b>Real-Time Tracking:</b> Keep track of your shipments with our advanced tracking systems, ensuring complete visibility throughout the journey.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Road Freight</h3>
                  </div>
                </div>
              </div>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[1] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service2} alt="Rail Freight" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Rail Freight</h3>
                    <p>
                      QFM's Rail Freight services offer an efficient and environmentally-friendly solution for transporting large volumes of goods across the country. Utilizing the vast rail network, we ensure your shipments are moved quickly and securely, providing an ideal balance between speed and cost.
                    </p>
                    <ul>
                      <li><b>Cost-Effective Transport:</b> Rail freight provides a more economical option for moving large quantities of goods over long distances.</li>
                      <li><b>Sustainability:</b> Reduce your carbon footprint with our environmentally-friendly rail solutions, minimizing the environmental impact of your logistics operations.</li>
                      <li><b>Intermodal Solutions:</b> Seamlessly integrate rail freight with road and other transportation methods for a comprehensive logistics solution.</li>
                      <li><b>Large Volume Capacity:</b> Efficiently transport bulk goods and large shipments, taking advantage of the high capacity of rail freight.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Rail Freight</h3>
                  </div>
                </div>
              </div>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[2] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service3} alt="Air Freight" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Air Freight</h3>
                    <p>
                      Our Air Freight services provide the fastest and most efficient way to transport goods across Australia and internationally. Whether you need to move urgent shipments or high-value items, our Air Freight solutions ensure your cargo reaches its destination quickly and securely. Leveraging our strong relationships with major airlines and a global network, we offer flexible and tailored solutions to meet the unique needs of your business.
                    </p>
                    <ul>
                      <li><b>Fastest Delivery:</b> Air freight offers the quickest transit times, ensuring your urgent shipments arrive on schedule, no matter the distance.</li>
                      <li><b>Global Reach:</b> Access a worldwide network of destinations, connecting you to international markets with ease.</li>
                      <li><b>High Security:</b> Enhanced security measures and strict protocols ensure the safe transport of valuable and sensitive goods.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Air Freight</h3>
                  </div>
                </div>
              </div>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[3] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service4} alt="Local Freight" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Local Freight</h3>
                    <p>
                      QFM specializes in providing reliable and efficient local freight solutions across Melbourne. Whether you need ad hoc taxi-truck services or permanent transport solutions, we are equipped to handle all your local delivery needs. From small parcels to large loads, we offer a range of vehicles, including trays and hydraulic tailgates.
                    </p>
                    <ul>
                      <li><b>Ad Hoc Taxi-Truck Services:</b> On-demand transport solutions available at short notice, ideal for urgent or irregular deliveries.</li>
                      <li><b>Permanent Taxi-Truck Solutions:</b> Dedicated vehicles and drivers for regular, ongoing transport needs, ensuring consistent and reliable service.</li>
                      <li><b>Diverse Fleet:</b> Access to a wide range of vehicles, including trays, vans, and trucks with hydraulic tailgates, to accommodate all types of cargo.</li>
                      <li><b>Secure Handling:</b> Careful handling of all goods, including fragile or sensitive items, with tailored solutions to protect your cargo.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Local Freight</h3>
                  </div>
                </div>
              </div>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[4] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service5} alt="Project Freight" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Project Freight</h3>
                    <p>
                      QFM offers specialized Project Logistics services designed to handle the complex and challenging demands of large-scale projects. From the initial planning phase to the final delivery, we provide end-to-end logistics solutions tailored to meet the specific requirements of your project.
                    </p>
                    <ul>
                      <li><b>Comprehensive Planning:</b> Detailed logistics planning to coordinate all aspects of your project, including route surveys, risk assessments, and compliance with local regulations.</li>
                      <li><b>Specialized Equipment:</b> Access to a wide range of specialized equipment and vehicles, including heavy-lift cranes, low loaders, and multi-axle trailers, to handle oversized or heavy cargo.</li>
                      <li><b>End-to-End Management:</b> Comprehensive management of the entire logistics process, from procurement and transportation to on-site delivery and installation.</li>
                      <li><b>Safety & Compliance:</b> Strict adherence to safety standards and regulatory compliance.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Project Freight</h3>
                  </div>
                </div>
              </div>
              <div className="services-detail" ref={(el) => (serviceDetailRefs.current[5] = el)}>
                <Link to="/contactus" className="services-link">
                  <div className="services-request-quote">Request Quote</div>
                </Link>
                <img src={Service6} alt="Dangerous Goods" />
                <div className="services-detail-text">
                  <div className="services-detail-text-left">
                    <h3>Dangerous Goods</h3>
                    <p>
                      We specialize in the safe and compliant transportation of Dangerous Goods. Our expertise in handling hazardous materials ensures that your cargo is managed with the highest levels of safety and care, adhering to all relevant regulations. Whether you're shipping chemicals, flammable materials, or other hazardous substances, our DG services provide peace of mind and reliable delivery.
                    </p>
                    <ul>
                      <li><b>Compliance with Australian Regulations:</b> Adherence to all local, state, and federal regulations governing the transportation of dangerous goods.</li>
                      <li><b>Specialized Handling:</b> Trained personnel equipped with the knowledge and tools to handle hazardous materials safely.</li>
                      <li><b>Reliable Transport Network:</b> Access to a nationwide network of carriers that are certified to handle dangerous goods.</li>
                      <li><b>Custom Solutions:</b> Tailored logistics solutions that meet the unique requirements of each type of dangerous good.</li>
                    </ul>
                  </div>
                  <div className="services-detail-text-right">
                    <h3>Dangerous Goods</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
}

export default Services;
