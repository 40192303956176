import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/Technology.css';
import { Link } from 'react-router-dom';
// import Image1 from '../images/interstatefreight.webp';
// import Image2 from '../images/completeservices.webp';
import Image3 from '../images/technology.webp';

function Tech() {
    window.scrollTo(0, 0);
    const textContentRef = useRef(null);
    const imageContainerRef = useRef(null);
    const textContent2Ref = useRef(null);
    const requestQuoteRef = useRef(null);
    const textContent3Ref = useRef(null);

    useEffect(() => {
        const observerOptions = {
            threshold: 0.1, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target); // Stop observing once it's visible
                }
            });
        }, observerOptions);

        [textContentRef, imageContainerRef, textContent2Ref, requestQuoteRef, textContent3Ref].forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            [textContentRef, imageContainerRef, textContent2Ref, requestQuoteRef, textContent3Ref].forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <div className="tech-main-content">
            <div className="tech-content-container">
                <Header isScrolledInitially={true} />
                <div className="tech-vertical-line"></div>
                <div className="tech-section-1">
                    <div className="tech-text-content" ref={textContentRef}>
                        <h1>Technology</h1>
                        <p>At Quan Freight Management, we utilize cutting-edge technology to provide reliable freight services, ensuring your goods are transported safely and efficiently across Australia. Our expert team and state-of-the-art technology guarantee timely delivery and cost-effective solutions.</p>
                    </div>
                    <div className="tech-image-container" ref={imageContainerRef}>
                        <img src={Image3} alt="Technology" />
                    </div>
                </div>
                <div className="tech-sections-wrapper">
                    <div className="tech-section-2">
                        <div className="tech-text-content-2" ref={textContent2Ref}>
                            <h2>Machship</h2>
                            <p>At Quan Freight Management, we leverage the power of Machship, a cloud-based freight management platform, to streamline our logistics and enhance service delivery. Machship provides us with advanced tools that enable efficient management of freight operations, from order creation to final delivery.</p>
                            <ul>
                                <li><b>End-to-End Visibility:</b> offers real-time tracking and visibility across all freight movements, allowing us to monitor shipments closely and keep our clients informed at every stage.</li>
                                <li><b>Automated Processes:</b> automating complex tasks such as carrier selection, rate calculation, and consignment creation, reducing human error and increasing operational efficiency.</li>
                                <li><b>Seamless Integration:</b> seamlessly integrates with our existing systems, ensuring that all logistics processes are connected and data flows smoothly between departments.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tech-section-3">
                        <div className="tech-text-content-3" ref={textContent3Ref}>
                            <h2>Commitment to Innovation</h2>
                            <h3>Always pushing the boundaries of what's possible</h3>
                            <ul>
                                <li>State-of-the-art tracking systems</li>
                                <li>Automated logistics processes</li>
                                <li>Data-driven decision-making</li>
                                <li>Continuous improvement and innovation</li>
                                <li>Committed to sustainability and efficiency</li>
                            </ul>
                        </div>
                        <Link to="/contactus" className="tech-link"><div className="tech-request-quote" ref={requestQuoteRef}> Request Quote </div></Link>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Tech;
