import React from 'react';

const DevelopmentMessage = () => {
  return (
    <div className="development-message-container">
      <div className="development-message">
        <h1>Under Development</h1>
        <p>
          Our mobile website is currently under development. For a better experience, please visit us on a desktop.
        </p>
        <small>Thank you for your understanding!</small>
      </div>

      <a href="https://qfmlogistics.machship.com/" target="_blank" rel="noopener noreferrer" className="customer-portal-link">
        <div className="customer-portal-message">
          <h2>Enter Customer Portal</h2>
        </div>
      </a>
    </div>
  );
};

export default DevelopmentMessage;
