import React, { useEffect, useState, useRef } from 'react';
import '../css/MobileMainContent.css';
import '../css/MobileFooter.css';
import emailjs from 'emailjs-com';
import Header from './MobileHeader';
import Footer from './MobileFooter';
import { Link } from 'react-router-dom';
import Service1 from '../images/servicesroadfreight.webp';
import Service2 from '../images/servicesrailfreight.webp';
import Service3 from '../images/servicesairfreight.webp';
import Service4 from '../images/serviceslocalfreight.webp';
import Service5 from '../images/servicesprojectfreight.webp';
import Service6 from '../images/servicesdangerousgoods.webp';
import mainimage from '../images/QFM_Landing.jpg';

const services = [
    {
        title: 'Road Freight',
        image: Service1,
        description:
            'Our Road Freight services offer reliable and flexible transportation solutions across the country. With an extensive carrier network and customizable solutions, we ensure timely and cost-effective delivery for small parcels or large shipments. Our advanced tracking systems ensure peace of mind throughout the transport journey.',
    },
    {
        title: 'Rail Freight',
        image: Service2,
        description:
            'Our Rail Freight services provide an efficient and sustainable solution for transporting large volumes of goods across the country. Combining cost-effectiveness with environmental benefits, we offer seamless integration with other transport modes, ensuring an optimal balance between speed and cost for bulk shipments.',
    },
    {
        title: 'Air Freight',
        image: Service3,
        description:
            'QFM’s Air Freight services are designed for speed and security, making them the ideal choice for urgent or high-value shipments. With access to major airlines and a global network, our air freight solutions provide flexible, reliable, and time-sensitive deliveries to both domestic and international destinations.',
    },
    {
        title: 'Local Freight',
        image: Service4,
        description:
            'Our Local Freight services offer reliable and efficient transport solutions within Melbourne and surrounding areas. Whether you need on-demand taxi-truck services or regular deliveries, we have a wide range of vehicles to cater to all local delivery needs, ensuring fast and secure handling of your goods.',
    },
    {
        title: 'Project Freight',
        image: Service5,
        description:
            'QFM specializes in Project Freight services, managing complex logistics for large-scale projects. From route planning to final delivery, we provide end-to-end management, utilizing specialized equipment and tailored solutions for oversized or heavy cargo. Our focus on safety and compliance ensures the successful delivery of project cargo.',
    },
    {
        title: 'Dangerous Goods',
        image: Service6,
        description:
            'Our Dangerous Goods services ensure safe and compliant transportation of hazardous materials. With extensive experience and strict adherence to regulations, we handle all types of dangerous goods, offering peace of mind and reliable delivery across Australia.',
    },
];

function MobileMainContent() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalCards = services.length;
    const timerRef = useRef(null); // For auto-rotate
    const elementsToAnimate = useRef([]);

    // For form handling
    const [formErrors, setFormErrors] = useState({});
    const [formValues, setFormValues] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        remarks: '',
    });
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const resetTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
        }, 10000);
    };

    useEffect(() => {
        resetTimer();
        return () => clearInterval(timerRef.current);
    }, [totalCards]);

    // Handle manual navigation
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
        resetTimer();
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? totalCards - 1 : prevIndex - 1
        );
        resetTimer();
    };

    // Form handling logic
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
        setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formValues.name) errors.name = 'Name is required';
        if (!formValues.company) errors.company = 'Company is required';
        if (!formValues.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formValues.phone) {
            errors.phone = 'Phone number is required';
        } else if (!/^\d+$/.test(formValues.phone)) {
            errors.phone = 'Phone number is invalid';
        }
        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const templateParams = {
                name: formValues.name,
                company: formValues.company,
                email: formValues.email,
                phone: formValues.phone,
                remarks: formValues.remarks,
            };

            emailjs
                .send('service_eqjos09', 'template_6v12fmu', templateParams, '6hHglwaeH0hQQmjcJ')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text); // Log success
                    setAlertMessage('Thank you! Your information request has been submitted.');
                    setAlertType('success');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 2000);
                    setFormValues({
                        name: '',
                        company: '',
                        email: '',
                        phone: '',
                        remarks: '',
                    });
                })
                .catch((error) => {
                    console.log('FAILED...', error); // Log error
                    setAlertMessage('There was an issue submitting your request. Please try again later.');
                    setAlertType('error');
                    setAlertVisible(true);
                    setTimeout(() => setAlertVisible(false), 2000);
                });

        }
    };

    // Fade-in effect handling
    useEffect(() => {
        const observerOptions = { threshold: 0.3 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-visible');
                }
            });
        }, observerOptions);

        elementsToAnimate.current.forEach((element) => {
            if (element) observer.observe(element);
        });

        return () => {
            elementsToAnimate.current.forEach((element) => {
                if (element) observer.unobserve(element);
            });
        };
    }, []);

    useEffect(() => {
        const imageElement = document.querySelector('.maincontent-landing-image');
        
        // Delay the zoom effect slightly after page load
        setTimeout(() => {
            if (imageElement) {
                imageElement.classList.add('zoomed');
            }
        }, 100); // Adjust the delay if needed
    }, []);
    




    return (
        <div className="maincontent-page">
            <Header />
            {alertVisible && (
                <div className={`maincontent-alert maincontent-alert-${alertType}`}>
                    {alertMessage}
                </div>
            )}

            {/* Introduction Section */}
            <section className="maincontent-introduction fade-in-left" ref={(el) => (elementsToAnimate.current[0] = el)}>
                <div className="maincontent-intro-content">
                    <p>
                        Welcome to <span className="qfm-transition">Quan Freight Management</span>!
                    </p>
                </div>
            </section>


            <section className="maincontent-image-section fade-in-up"ref={(el) => (elementsToAnimate.current[4] = el)}>
                <img src={mainimage} alt="Quan Freight Management" className="maincontent-landing-image" />
            </section>
            
            <section className="maincontent-introduction-2 fade-in-left" ref={(el) => (elementsToAnimate.current[5] = el)}>
                <div className="maincontent-intro-content">
                    <p>
                        We provide a full range of domestic freight solutions across Australia, ensuring timely and cost-effective logistics by road, rail, and air. Let us simplify your logistics, so you can focus on growing your business.
                    </p>
                </div>
            </section>




            {/* Carousel Section */}
            <section className="maincontent-services fade-in-up" ref={(el) => (elementsToAnimate.current[1] = el)}>
                <div className="maincontent-carousel">
                    <div className="maincontent-service-card">
                        <div className="maincontent-image-container">
                            <img src={services[currentIndex].image} alt={services[currentIndex].title} />
                            <h2 className="maincontent-image-title">
                                <button className="maincontent-carousel-prev1" onClick={handlePrev}>←</button>
                                {services[currentIndex].title}
                                <button className="maincontent-carousel-next1" onClick={handleNext}>→</button>
                            </h2>
                        </div>
                        <p>{services[currentIndex].description}</p>
                    </div>
                </div>
            </section>

            {/* About Us Section */}
            <section className="maincontent-aboutus-section fade-in-left" ref={(el) => (elementsToAnimate.current[2] = el)}>
                <h2>Our Offer to You</h2>
                <h3>Your shipment is always our first priority</h3>
                <ul>
                    <li>Reliable and timely delivery across Australia</li>
                    <li>Comprehensive logistics solutions tailored to your needs</li>
                    <li>State-of-the-art technology for efficient tracking</li>
                    <li>Cost-effective transport options to optimize your budget</li>
                    <li>Experienced and professional team dedicated to excellence</li>
                </ul>
            </section>

            {/* Contact Form Section */}
            <section className="maincontent-contact-form fade-in-up" ref={(el) => (elementsToAnimate.current[3] = el)}>
                <h2>Contact Us</h2>
                <h3>Reach out for quotes, information or to discuss your logistics needs</h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">
                            Name {formErrors.name && <span className="error">{formErrors.name}</span>}
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formValues.name}
                            onChange={handleInputChange}
                            className={formErrors.name ? 'input-error' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="company">
                            Company {formErrors.company && <span className="error">{formErrors.company}</span>}
                        </label>
                        <input
                            type="text"
                            id="company"
                            name="company"
                            value={formValues.company}
                            onChange={handleInputChange}
                            className={formErrors.company ? 'input-error' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">
                            Email {formErrors.email && <span className="error">{formErrors.email}</span>}
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            className={formErrors.email ? 'input-error' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">
                            Phone {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            className={formErrors.phone ? 'input-error' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="remarks">Remarks</label>
                        <textarea
                            id="remarks"
                            name="remarks"
                            value={formValues.remarks}
                            onChange={handleInputChange}
                            rows="4"
                            maxLength="280"
                        ></textarea>
                    </div>

                    <div class="submit-button-container">
                        <button type="submit" className="submit-button">Send</button>
                    </div>

                </form>
            </section>
            <Footer />
        </div>
    );
}

export default MobileMainContent;
